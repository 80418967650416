import { Directionality } from '@angular/cdk/bidi';
import { DOCUMENT, isPlatformBrowser, ViewportScroller } from '@angular/common';
import { Component, OnInit, HostListener, Inject, PLATFORM_ID, Renderer2, RendererFactory2, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd, Data } from '@angular/router';

import { Subscription, interval, Subject } from 'rxjs';
import { filter, take, tap, finalize } from 'rxjs/operators';

import { ColourConstant } from 'src/app/constants/colourConstant';
import { Constant } from 'src/app/constants/constant';
import { MenuItems } from 'src/app/Core/menu/menu-items/menu-items';
import { AnalyticService } from 'src/app/services/analytic-service';
import { CalsoftService } from 'src/app/services/Calsoft.service';
import { CartService } from 'src/app/services/cart.service';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';
import { PixelService } from 'src/app/services/pixel.service';
import { UserService } from 'src/app/services/user.service';
import { CommonSignInComponent } from 'src/app/shared/CommonSignIn/CommonSignIn.component';
import { PullToRefreshService } from 'src/app/theme/pull-to-refresh/pull-to-refresh.service';
declare var $: any;
declare var Tawk_API: any;
import { OneSignal } from 'onesignal-ngx';
import { GoogleMapService } from 'src/app/services/google-map.service';
import { LocationComponent } from 'src/app/shared/location/location.component';
import { ShareChatTrackingService } from 'src/app/services/share-chat-tracking.service';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderService } from 'src/app/services/loader.service';
import { NewsletterPopupService } from 'src/app/services/newsletter-popup.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import { NewsletterAdsComponent } from 'src/app/theme/components/newsletter-ads/newsletter-ads.component';
import { DynamicFormPopupComponent } from 'src/app/theme/components/dynamic-form-popup/dynamic-form-popup.component';
import { TiktokService } from 'src/app/services/tiktok.service';
@Component({
   selector: 'app-main',
   templateUrl: './main.component.html',
   styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
   timer = 0;
   isRtl: any;
   private _dirChangeSubscription = Subscription.EMPTY;
   currentUrl: any;
   categoryArray: any=[];
   type: any;
   theme: any;
   url: string;
   page1: any;
   data: unknown;
   userLoggedIn: boolean;
   languageSelection: any;
   breadCrumpDisable: boolean = false;
   public showBackToTop: boolean = false;
   disabled: boolean = false;
   display: boolean;
   finalData: any;
   hideFooterMob: any = 0;
   defaultFooter: any = 0;
   headerEnabled: any = 0;
   headerBannerRotateEnable: any = 0;
   footerScrollTextEnable: any = 0;
   private loaded: boolean;
   lastScrollPosition = 0;

   hideBreadcrump: boolean = false;
   iframeUrl: string;
   footerLayout: any;
   hideBr: boolean = false;

   init: boolean = false;
   public spinnerEnabled: any;
   containerClass = 'container';
   current = "/";
   displayFooterOnMOb: boolean = false;
   bannerLayoutList: any = [];
   deliveryRange: any;
   commonData: any;
   destinationLat: any;
   destinationLng: any;
   headerNotificationContent: any;
   public serverPath = environment.commonImageApi + "logo/logo.png";
   public serverPath4 = environment.commonImageApi + "logo/";

   public serverPath2 = environment.commonImageApi + "headerImage/";

   public serverPath5 = environment.commonImageApi + "homePageBackgroundImgSrc/";

   websiteBackgoundColor: any = 'white';
   headerLogoSrc: any;
   headerBannerTextColour: any;

   homePageBackgroundImgSrc: any;

   homePageBackgroundEnable: any;

   adsEnableHomePage: any;

   typeFormEnableHomePage: any;

   lastScrollTop = 0;
   heightSet: boolean = false;
   adsHomePageType: any;
   loadedPopup: boolean = false;
   enableFooterWebsite: any;
   seoList: any = [];
   public serverPathFav = environment.commonImageApi + "logo/";
   enableBreadcrumbPages: any;
   scroll_value: number;
   storePhoneNumber: any;
   enableWhatsappChat: any = 0;

   noPaddingFooter: any = 0;

   constructor(

      public commonService: CommonService,
      public calsoftService: CalsoftService,
      public menuItems: MenuItems,
      private categoryService: CategoryService,
      dir: Directionality,
      public router: Router,
      private _activatedRoute: ActivatedRoute,
      public dialog: MatDialog,
      public userService: UserService,
      public meta: Meta, public title: Title,
      private cartServices: CartService,
      public googleAnalyticsService: AnalyticService,
      @Inject(PLATFORM_ID) private platformId: Object,
      private titleService: Title,
      private pixelService: PixelService,
      private pullToRefreshService: PullToRefreshService,
      private rendererFactory: RendererFactory2,
      @Inject(DOCUMENT) private document: Document,
      private viewScroller: ViewportScroller,
      //  protected $gaService: GoogleAnalyticsService
      private oneSignal: OneSignal,
      private maps: GoogleMapService,
      private renderer: Renderer2,
      private snackBar: MatSnackBar,
      private loaderService: LoaderService,
      private wishlistService: WishlistService,
      private newsletterPopupService: NewsletterPopupService,
      private tiktokService: TiktokService
   ) {



      if (this.current.includes('fullwidth')) {
         this.containerClass = 'container-fluid';
      } else {
         this.containerClass = 'container';
      }



      if (this.calsoftService.isDirectionRtl) {
         this.isRtl = 'rtl';
      } else {
         this.isRtl = 'ltr';
      }

      this.router.events
         .subscribe((event) => {
            if (event instanceof NavigationEnd) {
               this.currentUrl = event.url;
               this.url = this.router.url;

            }
         });
   }

   targetElement: Element;

   public serverPathlogo = environment.commonImageApi + "logo/";
   public appId: any;
   ngOnInit() {


      this.getSeo();
      this.oneSignal.getUserId(function (id) {
      });

      const ua = navigator.userAgent
      // alert(ua)
      if (/iPad|iPhone|iPod/.test(ua)) {


         this.hideBr = true;



      }


      this.type = 'four';
      this.theme = 'blue';

      this.targetElement = document.querySelector('html');
      this.userLogged();

      this.init = true;

      if (this.router.url == '/' || this.router.url == '/home') {

         this.hideBreadcrump = true;
      }

      if (this.router.url == '/home') {
         this.currentUrl = '/home'
      }
      if (this.router.url == '/account/info') {
         this.currentUrl = '/account/info'
      }
      if (this.router.url == '/account/wishlist') {
         this.currentUrl = '/account/wishlist'
      }
      if (this.router.url == '/cart') {
         this.currentUrl = '/cart'
      }
      if (this.router.url == '/store') {
         this.currentUrl = '/store'
      }

      this.router.events.pipe(
         filter(event => event instanceof NavigationEnd)
      ).subscribe(event => {
         this.data = event;
         this.currentUrl = this.data['url']
      });
      this.startTimer();
      this.getCategories();
      let refId = this._activatedRoute.snapshot.queryParamMap.get("refId");
      if (refId != null) {
         sessionStorage.setItem(Constant.REFERAL_ID, refId);
         sessionStorage.setItem(Constant.REFERRED_PRODUCT_ID
            , "0");

      }
      this._activatedRoute.data.subscribe((data: Data) => {


         if (data.initialData.totalValue.enableBreadcrumbPages != undefined) {
            this.enableBreadcrumbPages = data.initialData.totalValue.enableBreadcrumbPages;

         } else {
            this.enableBreadcrumbPages = 0;
         }

         if (this.router.url.includes("/pages/") && this.enableBreadcrumbPages == 0) {
            this.breadCrumpDisable = true;
         } else if (this.router.url.includes("/contact-us") && this.enableBreadcrumbPages == 0) {
            this.breadCrumpDisable = true;
         } else if (this.router.url.includes("/aboutus") && this.enableBreadcrumbPages == 0) {
            this.breadCrumpDisable = true;
         } else if (this.router.url.includes("/terms&conditions") && this.enableBreadcrumbPages == 0) {
            this.breadCrumpDisable = true;
         } else if (this.router.url.includes("/privacy") && this.enableBreadcrumbPages == 0) {
            this.breadCrumpDisable = true;
         } else if (this.router.url.includes("/shippingPolicy") && this.enableBreadcrumbPages == 0) {
            this.breadCrumpDisable = true;
         } else if (this.router.url.includes("/paymentPolicy") && this.enableBreadcrumbPages == 0) {
            this.breadCrumpDisable = true;
         } else if (this.router.url.includes("/returnPolicy") && this.enableBreadcrumbPages == 0) {
            this.breadCrumpDisable = true;
         } else if (this.router.url.includes("pr?sid")) {
            this.breadCrumpDisable = true;
         } else if (this.router.url.includes("/search")) {
            this.breadCrumpDisable = true;
         } else if (this.router.url.includes("/product/")) {
            this.breadCrumpDisable = true;
            this.heightSet = true;
         } else if (this.router.url.includes("/booking-detail/")) {
            this.breadCrumpDisable = true;
            this.heightSet = true;
         } else if (this.router.url.includes("/product-list-temp")) {
            this.breadCrumpDisable = true;
            this.heightSet = true;
         } 
         // else if (this.router.url.includes("/home")) {
         //    this.breadCrumpDisable = true;
         //    this.heightSet = true;
         // }

         else {
            this.breadCrumpDisable = false;
         }
         let basicAuthHeaderString = this.userService.getAuthenticatedToken();

         let viewcol

         // if (data.initialData.totalValue['productsImageWidth'] > 250) {
         //    viewcol = 3
         // }

         if (data.initialData.totalValue['productsDisplayCount'] != undefined) {
            viewcol = data.initialData.totalValue['productsDisplayCount'];
         }


         localStorage.setItem('pgs', viewcol.toString())
         if (basicAuthHeaderString == null && data.initialData.totalValue['guestLogin'] == 1) {
            this.cartServices.getCustomerCartProductCount();

            this.cartServices.getCount();
         } else if (basicAuthHeaderString != null) {
            this.cartServices.getCustomerCartProductCount();
            this.cartServices.geWishListProduct();

         }

         this.finalData = data.initialData.totalValue
         this.footerLayout = data.initialData.totalValue['footerLayout'] == undefined ? 1 : data.initialData.totalValue['footerLayout']
         if (data.initialData.totalValue != null) {
            if (data.initialData.totalValue['googleSiteVerification'] != null && data.initialData.totalValue['googleSiteVerification'] != undefined && data.initialData.totalValue['googleSiteVerification'] != '') {
               this.meta.addTags([
                  { name: 'google-site-verification', content: data.initialData.totalValue['googleSiteVerification'] },

               ]);
            }

            if (data.initialData.totalValue['facebookPixelId'] != null && data.initialData.totalValue['facebookPixelId'] != undefined && data.initialData.totalValue['facebookPixelId'] != '') {

               this.pixelService.initialize(data.initialData.totalValue['facebookPixelId'])
            }

            //this.tiktokService.initialize('CPFD06RC77U9E3RD1D50')


            if (data.initialData.totalValue['googleMapsEnabled'] == 1) {

               let lat = localStorage.getItem('latitude');
               let long = localStorage.getItem('longitude');
               if (lat == null && long == null) {
                  this.commonData = data.initialData.totalValue;
                  this.deliveryRange = data.initialData.totalValue['deliveryRange'];
                  this.destinationLat = data.initialData.totalValue['supplierLatitude'];
                  this.destinationLng = data.initialData.totalValue['supplierLongitude'];
                  this.addGpsAddress(this.commonData);
               }

            }

            if (data.initialData.totalValue['faviconEnable'] == 1) {

               this.setFavicon(this.serverPathFav + 'favicon.ico');
            } else {
               this.setFavicon(this.serverPath);
            }

            if (data.initialData.totalValue['chatEnabled'] == 1) {

               if (data.initialData.totalValue['chatVendor'] == '1') {


                  this.renderer = this.rendererFactory.createRenderer(null, null);
                  this.load(data.initialData.totalValue['tawkToUrl']);


               }

            }

            const apiId = data.initialData.totalValue['wheeloScriptId'];

            //   console.log(data.initialData.totalValue['wheeloScriptId']);

            this.spinnerEnabled = data.initialData.totalValue['spinnerEnabled'] == undefined ? 1 : data.initialData.totalValue['spinnerEnabled']

            this.websiteBackgoundColor = data.initialData.totalValue['websiteBackgoundColor'] == undefined ? '#FFFFFF' : data.initialData.totalValue['websiteBackgoundColor']

            if (this.spinnerEnabled == 1) {
               this.loadWheelOfPopupsScript(apiId);
            }

            // this.loadScript(data.initialData.totalValue['googleApi'])

            localStorage.setItem(Constant['GUEST_LOGIN'], data.initialData.totalValue['guestLogin'])


            if (data.initialData.totalValue['fbVerificationCode'] != null && data.initialData.totalValue['fbVerificationCode'] != undefined && data.initialData.totalValue['fbVerificationCode'] != '') {
               this.meta.addTags([
                  { name: 'facebook-domain-verification', content: data.initialData.totalValue['fbVerificationCode'] },

               ]);
            }
            if (data.initialData.totalValue['googleAnalyticsId'] != null && data.initialData.totalValue['googleAnalyticsId'] != undefined && data.initialData.totalValue['googleAnalyticsId'] != '') {
               let key = data.initialData.totalValue['googleAnalyticsId'];
               //construct iframe url
               this.iframeUrl = "https://www.googletagmanager.com/ns.html?id=" + key
               //  register google tag manager
               const gTagManagerScript = document.createElement('script');
               gTagManagerScript.async = true;
               gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${key}`;
               document.head.appendChild(gTagManagerScript);

               // register google analytics
               const gaScript = document.createElement('script');
               gaScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', '${key}');
        `;
               document.head.appendChild(gaScript);

            }


         }

         let logo = this.serverPath;

         let currentURL = window.location.href;

         let type = 'website';

         let value = "en_US"
         this.meta.addTags([
            { name: 'author', content: Constant.siteName },
            { name: 'keywords', content: data.initialData.totalValue.keywords },
            { name: 'description', content: data.initialData.totalValue.description },
            { name: 'og:title', content: data.initialData.totalValue.author },
            { name: 'og:url', currentURL },
            { name: 'og:description', content: data.initialData.totalValue.description },
            { name: 'og:image', logo },
            { name: 'twitter:title', content: data.initialData.totalValue.author },
            { name: 'twitter:description', content: data.initialData.totalValue.description },
            { name: 'og:locale', value },
            { name: 'og:type', type },
         ]);
         this.type = data.initialData.layout;
         this.theme = data.initialData.theme;
         this.languageSelection = data.initialData.languageSelection;
         this.hideFooterMob = data.initialData.totalValue.hideFooterMob;
         this.defaultFooter = data.initialData.totalValue.defaultFooter;

         // this.adsEnableHomePage = data.initialData.totalValue.adsEnableHomePage;

         this.headerLogoSrc = data.initialData.totalValue.headerLogoSrc;

         this.storePhoneNumber = data.initialData.totalValue.storePhoneNumber;

         this.enableWhatsappChat = data.initialData.totalValue.enableWhatsappChat;

         this.homePageBackgroundImgSrc = data.initialData.totalValue.homePageBackgroundImgSrc;

         this.headerBannerTextColour = data.initialData.totalValue.headerBannerTextColour;


         if (data.initialData.totalValue.enableBreadcrumbPages != undefined) {
            this.enableBreadcrumbPages = data.initialData.totalValue.enableBreadcrumbPages;

         } else {
            this.enableBreadcrumbPages = 0;
         }

         if (data.initialData.totalValue.noPaddingFooter != undefined) {
            this.noPaddingFooter = data.initialData.totalValue.noPaddingFooter
         } else {
            this.noPaddingFooter = 0;
         }

         if (data.initialData.totalValue.homePageBackgroundEnable != undefined) {
            this.homePageBackgroundEnable = data.initialData.totalValue.homePageBackgroundEnable;
         } else {
            this.homePageBackgroundEnable = 0;
         }

         if (data.initialData.totalValue.enableFooterWebsite != undefined) {
            this.enableFooterWebsite = data.initialData.totalValue.enableFooterWebsite;
         } else {
            this.enableFooterWebsite = 0;
         }

         if (data.initialData.totalValue.adsEnableHomePage != undefined) {
            this.adsEnableHomePage = data.initialData.totalValue.adsEnableHomePage;

         } else {
            this.adsEnableHomePage = 0;
         }

         if (data.initialData.totalValue.typeFormEnableHomePage != undefined) {
            this.typeFormEnableHomePage = data.initialData.totalValue.typeFormEnableHomePage;

         } else {
            this.typeFormEnableHomePage = 0;
         }


         if (data.initialData.totalValue.adsHomePageType != undefined) {
            this.adsHomePageType = data.initialData.totalValue.adsHomePageType;

         } else {
            this.adsHomePageType = 0;
         }


         if (this.router.url.includes('/home') || this.router.url === '/') {
            if (!this.commonService.getPopupStatus()) {
               if (this.adsHomePageType === 1 && this.adsEnableHomePage === 1) {
                  this.newsLetterPopup();
               } else if (this.adsHomePageType === 2) {
                  this.dynamicFormPopup();
               } else {
                  if (this.userLoggedIn) {
                     this.userLoggedIn = true;
                     if (this.typeFormEnableHomePage === 1) {
                        this.typeForm();
                     } else {

                     }
                  }
               }
               this.commonService.setPopupLoaded();
            }
         }






         if (data.initialData.totalValue.headerEnabled != undefined) {
            this.headerEnabled = data.initialData.totalValue.headerEnabled;
         }

         if (data.initialData.totalValue.headerBannerRotateEnable != undefined) {
            this.headerBannerRotateEnable = data.initialData.totalValue.headerBannerRotateEnable;
         }


         if (data.initialData.totalValue.footerScrollTextEnable != undefined) {
            this.footerScrollTextEnable = data.initialData.totalValue.footerScrollTextEnable;
         }

         if (this.hideFooterMob == 1) {

            if (window.innerWidth < 900) {
               this.displayFooterOnMOb = true;
            } else {
               this.displayFooterOnMOb = false;
            }

         } else {
            this.displayFooterOnMOb = false;
         }


         if (this.defaultFooter == 1 && window.innerWidth < 900) {
            this.display = true;
         } else {
            this.display = false;
         }

         if (data.initialData.totalValue.mobileHeaderColour == undefined) {
            data.initialData.totalValue.mobileHeaderColour = data.initialData.theme;
         }

         if (data.initialData.totalValue.headerIconColour == undefined) {
            data.initialData.totalValue.headerIconColour = '#FFFFFF';
         }

         let adminNotify = data.initialData.totalValue.pushNotificationEnable == undefined ? 0 : 1;

         if (adminNotify == 1) {
            if (data.initialData.totalValue.pushNotificationOneSignalAppId != undefined) {
               this.oneSignal.showNativePrompt();
               this.oneSignal.init({
                  appId: data.initialData.totalValue['pushNotificationOneSignalAppId'],
                  allowLocalhostAsSecureOrigin: true,
                  notifyButton: {
                     enable: true,
                  },
               });

            }
         }


         if (data.initialData.totalValue.headerIconColour != undefined) {
            if (data.initialData.totalValue.headerIconColour.length > 0) {
               document.documentElement.style.setProperty('--header--icon--colour', data.initialData.totalValue.headerIconColour);

            } else {
               document.documentElement.style.setProperty('--header--icon--colour', '#000000');
            }
         }

         if (data.initialData.totalValue.mobileHeaderColour != undefined) {
            if (data.initialData.totalValue.mobileHeaderColour.length > 0) {
               document.documentElement.style.setProperty('--mobile-header-colour', data.initialData.totalValue.mobileHeaderColour);

            } else {
               document.documentElement.style.setProperty('--mobile-header-colour', '#000000');
            }
         }

         if (data.initialData.totalValue.websiteBackgoundColor != undefined) {
            if (data.initialData.totalValue.websiteBackgoundColor.length > 0) {
               document.documentElement.style.setProperty('--main-colour', data.initialData.totalValue.websiteBackgoundColor);

            } else {
               document.documentElement.style.setProperty('--main-colour', '#ffff');
            }
         }
         if (data.initialData.totalValue.footerLayoutColour != undefined) {
            if (data.initialData.totalValue.footerLayoutColour.length > 0) {
               document.documentElement.style.setProperty('--footer-layout-colour', data.initialData.totalValue.footerLayoutColour);

            } else {
               document.documentElement.style.setProperty('--footer-layout-colour', '#000000');
            }
         }
         if (data.initialData.totalValue.footerLayoutFontColour != undefined) {
            if (data.initialData.totalValue.footerLayoutFontColour.length > 0) {
               document.documentElement.style.setProperty('--footer-layout-font-colour', data.initialData.totalValue.footerLayoutFontColour);

            } else {
               document.documentElement.style.setProperty('--footer-layout-font-colour', '#FFFFFF');
            }
         }

         // default padding

         //    if(data.initialData.totalValue.defaultPaddingLeft!=null){
         //       if(data.initialData.totalValue.defaultPaddingLeft.length>0){

         //          document.documentElement.style.setProperty('--homepage-paddding-left',data.initialData.totalValue.defaultPaddingLeft);

         //       }else{
         //          document.documentElement.style.setProperty('--homepage-paddding-left','20px');
         //       }
         //    }else{
         //       document.documentElement.style.setProperty('--homepage-paddding-left','20px');
         //    }

         //    if(data.initialData.totalValue.defaultPaddingRight!=null){    
         //    if(data.initialData.totalValue.defaultPaddingRight.length>0){

         //       document.documentElement.style.setProperty('--homepage-paddding-right',data.initialData.totalValue.defaultPaddingRight);

         //    }else{
         //       document.documentElement.style.setProperty('--homepage-paddding-right','20px');
         //    }
         // }else{
         //    document.documentElement.style.setProperty('--homepage-paddding-right','20px');
         // }

         //homepage gap

         if (data.initialData.totalValue.homePageScreenSize != null) {
            if (data.initialData.totalValue.homePageScreenSize.length > 0) {

               document.documentElement.style.setProperty('--homepage-screen-size', data.initialData.totalValue.homePageScreenSize);

            } else {
               document.documentElement.style.setProperty('--homepage-screen-size', '1290px');
            }
         } else {
            document.documentElement.style.setProperty('--homepage-screen-size', '1290px');
         }

         // xs
         if (data.initialData.totalValue.paddingGapXsLeft != null) {
            if (data.initialData.totalValue.paddingGapXsLeft.length > 0) {

               document.documentElement.style.setProperty('--homepage-paddding-xs-left', data.initialData.totalValue.paddingGapXsLeft);

            } else {
               document.documentElement.style.setProperty('--homepage-paddding-xs-left', '20px');
            }
         } else {
            document.documentElement.style.setProperty('--homepage-paddding-xs-left', '20px');
         }

         if (data.initialData.totalValue.paddingGapXsRight != null) {
            if (data.initialData.totalValue.paddingGapXsRight.length > 0) {


               document.documentElement.style.setProperty('--homepage-paddding-xs-right', data.initialData.totalValue.paddingGapXsRight);

            } else {
               document.documentElement.style.setProperty('--homepage-paddding-xs-right', '20px');
            }
         }
         else {
            document.documentElement.style.setProperty('--homepage-paddding-xs-right', '20px');
         }

         // sm

         if (data.initialData.totalValue.paddingGapSmLeft != null) {
            if (data.initialData.totalValue.paddingGapSmLeft.length > 0) {
               document.documentElement.style.setProperty('--homepage-paddding-sm-left', data.initialData.totalValue.paddingGapSmLeft);

            } else {
               document.documentElement.style.setProperty('--homepage-paddding-sm-left', '20px');
            }
         } else {
            document.documentElement.style.setProperty('--homepage-paddding-sm-left', '20px');
         }

         if (data.initialData.totalValue.paddingGapSmRight != null) {
            if (data.initialData.totalValue.paddingGapSmRight.length > 0) {

               document.documentElement.style.setProperty('--homepage-paddding-sm-right', data.initialData.totalValue.paddingGapSmRight);

            } else {
               document.documentElement.style.setProperty('--homepage-paddding-sm-right', '20px');
            }
         } else {
            document.documentElement.style.setProperty('--homepage-paddding-sm-right', '20px');
         }

         // md

         if (data.initialData.totalValue.paddingGapMdLeft != null) {
            if (data.initialData.totalValue.paddingGapMdLeft.length > 0) {

               document.documentElement.style.setProperty('--homepage-paddding-md-left', data.initialData.totalValue.paddingGapMdLeft);

            } else {
               document.documentElement.style.setProperty('--homepage-paddding-md-left', '20px');
            }
         } else {
            document.documentElement.style.setProperty('--homepage-paddding-md-left', '20px');
         }


         if (data.initialData.totalValue.paddingGapMdRight != null) {
            if (data.initialData.totalValue.paddingGapMdRight.length > 0) {

               document.documentElement.style.setProperty('--homepage-paddding-md-right', data.initialData.totalValue.paddingGapMdRight);

            } else {
               document.documentElement.style.setProperty('--homepage-paddding-md-right', '20px');
            }
         } else {
            document.documentElement.style.setProperty('--homepage-paddding-md-right', '20px');
         }

         // lg
         if (data.initialData.totalValue.paddingGapLgLeft != null) {
            if (data.initialData.totalValue.paddingGapLgLeft.length > 0) {

               document.documentElement.style.setProperty('--homepage-paddding-lg-left', data.initialData.totalValue.paddingGapLgLeft);

            } else {
               document.documentElement.style.setProperty('--homepage-paddding-lg-left', '20px');
            }
         } else {
            document.documentElement.style.setProperty('--homepage-paddding-lg-left', '20px');
         }

         if (data.initialData.totalValue.paddingGapLgRight != null) {
            if (data.initialData.totalValue.paddingGapLgRight.length > 0) {

               document.documentElement.style.setProperty('--homepage-paddding-lg-right', data.initialData.totalValue.paddingGapLgRight);

            } else {
               document.documentElement.style.setProperty('--homepage-paddding-lg-right', '20px');
            }
         } else {
            document.documentElement.style.setProperty('--homepage-paddding-lg-right', '20px');
         }

         // xl
         if (data.initialData.totalValue.paddingGapXlLeft != null) {
            if (data.initialData.totalValue.paddingGapXlLeft.length > 0) {

               document.documentElement.style.setProperty('--homepage-paddding-xl-left', data.initialData.totalValue.paddingGapXlLeft);

            } else {
               document.documentElement.style.setProperty('--homepage-paddding-xl-left', '20px');
            }
         } else {
            document.documentElement.style.setProperty('--homepage-paddding-xl-left', '20px');
         }


         if (data.initialData.totalValue.paddingGapXlRight != null) {
            if (data.initialData.totalValue.paddingGapXlRight.length > 0) {

               document.documentElement.style.setProperty('--homepage-paddding-xl-right', data.initialData.totalValue.paddingGapXlRight);

            } else {
               document.documentElement.style.setProperty('--homepage-paddding-xl-right', '20px');
            }
         } else {
            document.documentElement.style.setProperty('--homepage-paddding-xl-right', '20px');
         }


         // paddingEnd

         //mobile font Size

         if (data.initialData.totalValue.mobileFontSize != null) {
            if (data.initialData.totalValue.mobileFontSize.length > 0) {

               document.documentElement.style.setProperty('--mobile-font-size', data.initialData.totalValue.mobileFontSize);

            } else {
               document.documentElement.style.setProperty('--mobile-font-size', '14px');
            }
         } else {
            document.documentElement.style.setProperty('--mobile-font-size', '14px');
         }

         if (data.initialData.totalValue.hompageLayoutSpace != null) {
            if (data.initialData.totalValue.hompageLayoutSpace.length > 0) {

               document.documentElement.style.setProperty('--homepage-layout-space', data.initialData.totalValue.hompageLayoutSpace);

            } else {
               document.documentElement.style.setProperty('--homepage-layout-space', '10px');
            }
         } else {
            document.documentElement.style.setProperty('--homepage-layout-space', '10px');
         }


         //font family

         if (data.initialData.totalValue.websiteFontFamily != null) {
            if (data.initialData.totalValue.websiteFontFamily.length > 0) {

               document.documentElement.style.setProperty('--website-font-family', data.initialData.totalValue.websiteFontFamily);

            } else {
               document.documentElement.style.setProperty('--website-font-family', 'Roboto');
            }
         } else {
            document.documentElement.style.setProperty('--website-font-family', 'Roboto');
         }


         //category image

         if (data.initialData.totalValue.categoryImgHeight != null) {
            if (data.initialData.totalValue.categoryImgHeight.length > 0) {

               document.documentElement.style.setProperty('--homepage-category-img-height', data.initialData.totalValue.categoryImgHeight);

            } else {
               document.documentElement.style.setProperty('--homepage-category-img-height', '100px');
            }
         } else {
            document.documentElement.style.setProperty('--homepage-category-img-height', '100px');
         }

         if (data.initialData.totalValue.categoryImgWidth != null) {
            if (data.initialData.totalValue.categoryImgWidth.length > 0) {

               document.documentElement.style.setProperty('--homepage-category-img-width', data.initialData.totalValue.categoryImgWidth);

            } else {
               document.documentElement.style.setProperty('--homepage-category-img-width', '60%');
            }
         }
         else {
            document.documentElement.style.setProperty('--homepage-category-img-width', '60%');
         }


         //category image website

         if (data.initialData.totalValue.categoryImageDisplayHeight != null) {
            if (data.initialData.totalValue.categoryImageDisplayHeight.length > 0) {

               document.documentElement.style.setProperty('--homepage-category-image-display-height', data.initialData.totalValue.categoryImageDisplayHeight);

            } else {
               document.documentElement.style.setProperty('--homepage-category-image-display-height', '30px');
            }
         } else {
            document.documentElement.style.setProperty('--homepage-category-image-display-height', '30px');
         }

         //category image website

         if (data.initialData.totalValue.categoryImageDisplayWidth != null) {
            if (data.initialData.totalValue.categoryImageDisplayWidth.length > 0) {

               document.documentElement.style.setProperty('--homepage-category-image-display-weight', data.initialData.totalValue.categoryImageDisplayWidth);

            } else {
               document.documentElement.style.setProperty('--homepage-category-image-display-weight', '30px');
            }
         } else {
            document.documentElement.style.setProperty('--homepage-category-image-display-weight', '30px');
         }



         this.titleService.setTitle(data.initialData.totalValue.author);

         // document.documentElement.style.setProperty('--footer_layout_colour', ColourConstant.COMMON_ACCENT );
         // document.documentElement.style.setProperty('--footer_layout_colour', ColourConstant.COMMON_WARN);

         document.documentElement.style.setProperty('--accent-color', ColourConstant.COMMON_ACCENT);
         document.documentElement.style.setProperty('--warn-color', ColourConstant.COMMON_WARN);


         if (data.initialData.totalValue.predefinedColor == 2) {
            document.documentElement.style.setProperty('--primary-color', ColourConstant[this.theme + '_PRIMARY']);
            document.documentElement.style.setProperty('--border-color', ColourConstant[this.theme + '_PRIMARY']);
         }

         if (data.initialData.totalValue.predefinedColor == 3) {
            document.documentElement.style.setProperty('--primary-color', this.theme);
            document.documentElement.style.setProperty('--border-color', this.theme);

         }
      });




   }

   dynamicFormPopup() {
      if (window.innerWidth > 768) {

         let dialogRef = this.dialog.open(DynamicFormPopupComponent, {
            width: '790px',
         });

         dialogRef.disableClose = true;
         dialogRef.afterClosed().subscribe(result => {

         });

      }
      else {
         let dialogRef = this.dialog.open(DynamicFormPopupComponent, {
            minWidth: '100vw', height: '100vh',
         });

         dialogRef.disableClose = true;
         dialogRef.afterClosed().subscribe(result => {

         });
      }

   }


   newsLetterPopup() {
      if (window.innerWidth > 768) {

         let dialogRef = this.dialog.open(NewsletterAdsComponent, {
            width: '790px',
         });

         dialogRef.disableClose = true;
         dialogRef.afterClosed().subscribe(result => {
            //  this.ngOnInit();


         });

      }
      else {
         let dialogRef = this.dialog.open(NewsletterAdsComponent, {
            minWidth: '100vw', height: '100vh',
         });

         dialogRef.disableClose = true;
         dialogRef.afterClosed().subscribe(result => {
            //  this.ngOnInit();


         });
      }

   }

   typeForm() {
      if (window.innerWidth > 768) {

         let dialogRef = this.dialog.open(NewsletterAdsComponent, {
            width: '790px',
         });

         dialogRef.disableClose = true;
         dialogRef.afterClosed().subscribe(result => {
            //  this.ngOnInit();


         });

      }
      else {
         let dialogRef = this.dialog.open(NewsletterAdsComponent, {
            minWidth: '100vw', height: '100vh',
         });

         dialogRef.disableClose = true;
         dialogRef.afterClosed().subscribe(result => {
            //  this.ngOnInit();


         });
      }

   }

   //private maxSwipes = 5;
   //private swipeCount = 0;

   private startX = 0;
   private startY = 0;
   private swipeThreshold = 50; // Adjust the threshold as needed

   onTouchStart(event: TouchEvent) {
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
   }

   onTouchEnd(event: TouchEvent) {
      const endX = event.changedTouches[0].clientX;
      const endY = event.changedTouches[0].clientY;

      const deltaX = endX - this.startX;
      const deltaY = endY - this.startY;

      // Ensure the horizontal swipe is greater than the threshold and the vertical swipe is within the threshold
      if (Math.abs(deltaX) > this.swipeThreshold && Math.abs(deltaY) < this.swipeThreshold) {
         if (deltaX > 0) {
            // Swiping right
            console.log('Swiped right!');

            if (this.router.url == '/cart') {
               this.loaderService.hide();
               this.router.navigate(['/account/wishlist']);
            }
            else if (this.router.url == '/account/wishlist') {
               this.loaderService.hide();
               this.router.navigate(['/account/info']);
            }
            else if (this.router.url == '/account/info') {
               this.loaderService.hide();
               this.router.navigate(['/category']);
            }
            else if (this.router.url == '/category') {
               this.loaderService.hide();
               this.router.navigate(['/home']);
            }
         } else {
            // Swiping left
            console.log('Swiped left!');

            if (this.router.url == '/home') {
               this.loaderService.hide();
               this.router.navigate(['/category']);
            }
            else if (this.router.url == '/category') {
               this.loaderService.hide();
               this.router.navigate(['/account/info']);
            }
            else if (this.router.url == '/account/info') {
               this.loaderService.hide();
               this.router.navigate(['/account/wishlist']);
            }
            else if (this.router.url == '/account/wishlist') {
               this.loaderService.hide();
               this.router.navigate(['/cart']);
            }
         }
      }
   }

   // onSwipeLeft() {
   //    this.swipeCount++;

   //    if (this.swipeCount > this.maxSwipes) {
   //      this.swipeCount = 1;
   //    }

   //    this.navigateBasedOnSwipeCount();

   //    console.log('Swiped left!');
   //  }

   //  onSwipeRight() {
   //    this.swipeCount--;

   //    if (this.swipeCount < 1) {
   //      this.swipeCount = this.maxSwipes;
   //    }

   //    this.navigateBasedOnSwipeCount();

   //    console.log('Swiped right!');
   //  }

   //  private navigateBasedOnSwipeCount() {
   //    const footerMenuItem = this.footerMenu.find(item => item.url === this.router.url);

   //    if (footerMenuItem) {
   //      const currentIndex = this.footerMenu.indexOf(footerMenuItem);
   //      const nextIndex = (currentIndex + this.swipeCount - 1 + this.footerMenu.length) % this.footerMenu.length;

   //      const nextUrl = this.footerMenu[nextIndex].url;
   //      this.router.navigate([nextUrl]);
   //    }
   //  }

   getBannerLayout() {
      this.commonService.getBannerLayout().subscribe(
         result => {
            this.bannerLayoutList = result;
         },
         error => {
         }
      )
   }

   public startTimer() {
      this.timer = 0;
      interval(1000).pipe(
         take(3),
         tap(value => { this.timer = value + 1; }),
         finalize(() => "s"),
      ).subscribe();

      // We're sure that subscription has been made, we can start loading bar service
   }

   getCategories() {
      this.categoryService.categoryList().subscribe(
         result => {
            this.categoryArray = result['data'];
         },
         error => {
         }
      )
   }
   public hideSideNav() {
      this.calsoftService.sidenavOpen = false;
   }

   public changeDirection() {
      if (this.isRtl == "rtl") {
         this.isRtl = "ltr";
         this.calsoftService.isDirectionRtl = false;
      } else {
         this.isRtl = "rtl"
         this.calsoftService.isDirectionRtl = true;
      }

      this.calsoftService.featuredProductsSelectedTab = 0;
      this.calsoftService.newArrivalSelectedTab = 0;
   }

   /**
    * On window scroll add class header-fixed.
    */




   footerMenu: any = [
      { name: 'Home', icon: 'home', url: '/home', secured: false },
      { name: 'Categories', icon: 'category', url: '/category', secured: false },
      { name: 'My Account', icon: 'person', url: '/account/info', secured: true },
      { name: 'Wishlist', icon: 'favorite_border', url: '/account/wishlist', secured: true },
      { name: 'Cart', icon: 'shopping_cart', url: '/cart', secured: true },
   ]

   account(i, j) {
      this.page1 = this.footerMenu[i].url
      if (!j) {
         this.router.navigate([this.page1])
      } else {
         let basicAuthHeaderString = this.userService.getAuthenticatedToken();
         let username = this.userService.getAuthenticatedUser();
         let guestLoginEnabled = localStorage.getItem(Constant['GUEST_LOGIN'])
         if (basicAuthHeaderString && username) {
            this.router.navigate([this.page1])
         } else if (basicAuthHeaderString == null && guestLoginEnabled == "1" && this.page1 == '/cart') {
            this.router.navigate([this.page1])
         }
         else {

            this.loginModel();

            // this.router.navigate(['/cart'])

         }
      }

      // this.calsoftService.sidenavOpen = false;

   }



   loginModel() {
      if (window.innerWidth > 768) {

         let dialogRef = this.dialog.open(CommonSignInComponent, {
            width: '790px',
            data: { page: this.page1, }
         });

         dialogRef.disableClose = true;
         dialogRef.afterClosed().subscribe(result => {
            this.ngOnInit();


         });

      }
      else {
         let dialogRef = this.dialog.open(CommonSignInComponent, {
            minWidth: '100vw', height: '100vh',
            data: { page: this.page1, }
         });

         dialogRef.disableClose = true;
         dialogRef.afterClosed().subscribe(result => {


            if (result['event'] != 'close') {
               this.router.navigate([this.page1])
            }

         });
      }

   }

   userLogged() {

      this.router.routeReuseStrategy.shouldReuseRoute = function () {
         return false;
      };

      let basicAuthHeaderString = this.userService.getAuthenticatedToken();
      let username = this.userService.getAuthenticatedUser();



      if (basicAuthHeaderString && username) {

         this.userLoggedIn = true;
         this.cartServices.getCount();

      }
      else {

         this.userLoggedIn = false;
      }
   }

   public scrollToTop() {
      this.viewScroller.scrollToPosition([0, 0]);
   }


   myRefreshEvent(event: Subject<any>, message: string) {
      this.userService.reload()
      event.next();

   }

   private load(src) {

      if (this.loaded)
         return;

      const s = this.renderer.createElement('script');
      s.async = true;
      s.text = `WIDGET_SCRIPT`;
      s.src = src;
      s.charset = 'UTF-8';
      s.setAttribute('crossorigin', '*');
      this.renderer.appendChild(this.document.body, s);
      this.loadedEvent.bind(this);
   }

   // private load(){
   //    if(this.loaded)
   //        return;

   //    // const s = this.renderer.createElement('script');
   //    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
   //    s1.async = true;
   //    s1.text = `WIDGET_SCRIPT`;
   //    s1.src='https://embed.tawk.to/62834a2eb0d10b6f3e728d3f/1g38djhqu';
   //    s1.charset='UTF-8';
   //    s1.setAttribute('crossorigin','*');
   //    s0.parentNode.insertBefore(s1,s0);
   //    //this.renderer.appendChild(this._document.body, s);
   //    this.loadedEvent.bind(this);
   // }


   private loadedEvent() {
      this.loaded = true;
   }


   //wheel



   loadWheelOfPopupsScript(apiId: string) {
      if (this.spinnerEnabled == 1) {
         const script = document.createElement('script');
         script.src = `https://www.wheelofpopups.com/api/${apiId}/widget.js`;
         script.type = 'text/javascript';
         script.defer = true;
         document.head.appendChild(script);
      }
   }

   addGpsAddress(commonData) {

      if (window.innerWidth > 768) {
         document.body.classList.add('dialog-open-scroll');
         let dialogRef = this.dialog.open(LocationComponent, {

            width: '790px',
            data: {
               page: 'home',
               fullDat: commonData,
            }
         });

         dialogRef.disableClose = true;
         dialogRef.afterClosed().subscribe(result => {
            if (result['event'] == 'add') {
               this.distanceCheck(result.data)
            }
            document.body.classList.remove('dialog-open-scroll');
         });

      }
      else {
         let dialogRef = this.dialog.open(LocationComponent, {
            minWidth: '100vw', height: '100vh',
            data: { page: 'home', fullDat: commonData }
         });
         document.body.classList.add('dialog-open-scroll');
         dialogRef.disableClose = true;
         dialogRef.afterClosed().subscribe(result => {
            if (result['event'] == 'add') {
               this.distanceCheck(result.data)
               document.body.classList.remove('dialog-open-scroll');
            }
         });
      }
   }


   async distanceCheck(res) {
      try {
         const result = await this.maps.getDistance(this.destinationLat,
            this.destinationLng, res.latitude, res.longitude);
         console.log(result);

         if (this.deliveryRange > result) {
            console.log('delivered');
         }
         if (this.deliveryRange == 0 || this.deliveryRange === 0) {
            console.log('delivered');
         }

         else if (this.deliveryRange < result) {
            //console.log('not delivered');
            localStorage.removeItem('latitude');
            localStorage.removeItem('longitude');
            this.router.navigate(['/locationNotFound']);
         }
         else {

         }

      } catch (e) {
         console.log(e);
      }

   }

   showTopNotice = true;

   closeTopNotice(): void {
      this.showTopNotice = false;
   }

   setFavicon(url: string): void {
      const link = this.renderer.createElement('link');
      link.setAttribute('rel', 'icon');
      link.setAttribute('type', 'image/png');
      link.setAttribute('href', url);
      this.renderer.appendChild(this.document.head, link);

   }

   copyToClipboard(text: string): void {
      const tempTextArea = document.createElement('textarea');
      tempTextArea.value = text;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand('copy');
      document.body.removeChild(tempTextArea);
      this.snackBar.open('Copied to clipboard!', 'X', {
         duration: 3000,
         verticalPosition: 'bottom',
         panelClass: ['success'],
      });
   }

   getSeo() {
      this.categoryService.getSeoPage().subscribe(
         res => {
            this.seoList = res;

            if (this.seoList.length > 0) {

               this.seoList.forEach(element => {

                  this.meta.addTags([
                     { name: element['name'], content: element['value'] },

                  ]);

               });
            }


         },
         error => {
         })
   }

   @HostListener('window:scroll', [])
   onWindowScroll() {
      const currentScrollPosition = window.pageYOffset;
      this.scroll_value = currentScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
   }

   navigate() {
      let message = `Hi`;
      const url = `https://api.whatsapp.com/send?phone= ${encodeURIComponent(this.storePhoneNumber)}&text=${encodeURIComponent(message)}`;
      this.commonService.goToLink(url)
   }


}

