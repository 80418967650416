<div class="theme-container" style="z-index: 1000;">
  <div class="navbar" style="z-index: 1000;">
    <a (click)="leftMenuToggle()">
      <div style="cursor: pointer;" class="bar-style">
        <mat-icon class="sidebar-bar">menu</mat-icon>
      </div>
    </a>
    <div id="mySidenav" class="sidenav" [class.openSide]="navServices?.leftMenuToggle">
      <a class="sidebar-overlay" (click)="leftMenuToggle()" (mouseover)="onHover(false)"></a>
      <nav id="unset" style="z-index: 1000;">
        <div (click)="leftMenuToggle()">
          <div class="sidebar-back text-start">
            <div style="display: flex;">
              <mat-icon class="pe-2">arrow_back_ios</mat-icon>
              <p class="btnback">Back</p>
            </div>
          </div>
        </div>
        <ul id="sub-menu" class="menu-vertical sf-arrows" *ngFor="let menuItem of categoryArray" #categoryElement>
          <li style="cursor: pointer;" class="megamenu-container" #menuItemRef (mouseover)="onHover(menuItem.children ? true : false)">
            <a *ngIf="menuItem.subcategories.length > 0" (click)="toggletNavActive(menuItem);" [ngClass]="{'sf-with-ul': menuItem.subcategories.length > 0}">
              <span class="menu-Title">{{menuItem.categoryName}}</span>
            </a>
            <a *ngIf="menuItem.subcategories.length == 0" style="cursor: pointer;" (click)="toggletNavActive(menuItem);goToCategory(menuItem)">
              <span class="menu-Title">{{menuItem.categoryName}}</span>
            </a>

            <!-- 2nd Level Menu -->
            <div [ngStyle]="calculateMegamenuPosition(categoryElement)" class="megamenu megamenu-sm" *ngIf="menuItem.subcategories.length > 0">
              <ul [class.opensub1]="menuItem.isActive == '1' ? true : false">
                <!-- Simple Menu Start -->
                <ng-container *ngIf="menuItem.mega == 'false'">
                  <div class="menu-container">
                    <li *ngFor="let childrenItem of menuItem.subcategories">
                      <div fxLayout="column">
                        <div (click)="goToCategory(childrenItem)" class="menu-title1" style="display: flex; padding: 10px; margin-bottom: 0px;">
                          <span>{{ childrenItem.categoryName }}</span>
                        </div>
                        <calsoft-left-menu-sub [category]="childrenItem.subcategories" class="sub-align"></calsoft-left-menu-sub>
                      </div>
                    </li>
                  </div>
                </ng-container>
                <!-- Simple Menu End -->

                <!-- Mega Menu Start -->
                <ng-container *ngIf="menuItem.mega == 'true'">
                  <li>
                    <div class="row m-0">
                      <div class="spaces" [style.width.%]="'100'" *ngFor="let childrenItem of menuItem.subcategories">
                        <div class="link-section">
                          <div class="menu-title" *ngIf="childrenItem.subcategories.length > 0">{{childrenItem.categoryName}}</div>
                          <div class="menu-title1" *ngIf="childrenItem.subcategories.length == 0" (click)="goToCategory(childrenItem)">{{childrenItem.categoryName}}</div>

                          <calsoft-left-menu-sub [category]="childrenItem.subcategories"></calsoft-left-menu-sub>
                        </div>
                      </div>
                    </div>
                  </li>
                </ng-container>
                <!-- Mega Menu End -->
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
