<div class="header-top" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="logo" fxFlex>
      <img src="" alt="logo">
    </div>
    <div class="select-city" fxFlex="none">
      <button (click)="openCitySelectionDialog()" class="slc">
        {{ selectedCity ? selectedCity.name : 'Select City' }}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
    </div>
    <div class="search-bar" fxFlex="none">
      <div class="search-box">
        <input type="search" placeholder="Search by make or model">
        <mat-icon>search</mat-icon>
      </div>
    </div>
    <div class="buttons" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <button>Buy Car</button>
      <button>Sell Car</button>
      <div class="dropdown" fxFlex="none">
        <button>More<mat-icon>arrow_drop_down</mat-icon></button>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <button><mat-icon>account_circle</mat-icon>Account</button>
      </div>
    </div>
    <div class="signup-login" fxFlex="none" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <button><mat-icon>person_outline</mat-icon>Login/Signup</button>
    </div>
  </div>
  <div class="header-bottom" fxLayout="row" fxLayoutAlign="center center">
    <div class="explore-us" fxFlex="none">
      <button>Explore Us</button>
    </div>
    <div class="price-range" fxFlex="none">
      <button>Price Range <mat-icon>arrow_drop_down</mat-icon></button>
    </div>
    <div class="make-model" fxFlex="none">
      <button>Make and Model <mat-icon>arrow_drop_down</mat-icon></button>
    </div>
    <div class="year" fxFlex="none">
      <button>Year <mat-icon>arrow_drop_down</mat-icon></button>
    </div>
    <div class="fuel" fxFlex="none">
      <button>Fuel <mat-icon>arrow_drop_down</mat-icon></button>
    </div>
    <div class="km-driven" fxFlex="none">
      <button>KM Driven <mat-icon>arrow_drop_down</mat-icon></button>
    </div>
    <div class="body-type" fxFlex="none">
      <button>Body Type <mat-icon>arrow_drop_down</mat-icon></button>
    </div>
    <div class="transmission" fxFlex="none">
      <button>Transmission <mat-icon>arrow_drop_down</mat-icon></button>
    </div>
  </div>
  